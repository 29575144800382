require('./stylesheets/base.scss')
require('./images/tech-radar-landing-page-wide.png')
require('./images/tw-logo.png')
require('./images/logo.png')
require('./images/favicon.ico')
require('./images/search-logo-2x.svg')
require('./images/banner-image-mobile.jpg')
require('./images/banner-image-desktop.jpg')
require('./images/new.svg')
require('./images/existing.svg')
require('./images/arrow-icon.svg')
require('./images/first-quadrant-btn-bg.svg')
require('./images/second-quadrant-btn-bg.svg')
require('./images/third-quadrant-btn-bg.svg')
require('./images/fourth-quadrant-btn-bg.svg')
require('./images/arrow-white-icon.svg')
require('./images/search-active-wave.svg')
require('./images/pdf_banner.png')
